<template>
	<div class="animated fadeIn p-4" />
</template>

<script>
import { Settings as messages } from '@/translations';
import Settings from '@/util/Settings';

export default {
	name: 'SettingsGeneral',
	messages,
	data() {
		return {
			settings: new Settings(),
			alert: new this.$Alert(),
		};
	},
	computed: {
		errors() {
			return this.settings.data.errors;
		},
		loading() {
			return !!this.settings.data.loading;
		},
	},
};
</script>
